import React, { useState } from "react";
import Footer from "../components/Footer";
import { Content, Header } from "../components/text";
import { ContentBox, NarrowColumn } from "../components/layout";
import Gap from "../components/Gap";
import { useHistory } from "react-router-dom";
import StyledCheckboxComponent from "../components/Checkbox";
import styled from "styled-components";
import { largerThan } from "../utils/styledComponents";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  height: 160px;
  marginBottom:  8px;

  ${largerThan.mobile`
    height: 400px;
    margin: 24px 0;
  `}
`;

const Tos = ({}) => {
  const history = useHistory();
  const [accepted, setAccepted] = useState(false);

  return (
    <NarrowColumn style={{ maxWidth: "670px" }}>
      <ContentBox>
        <Header>Terms of Service</Header>
        <Gap height={2} />
        <Content>
          Please read and agree to the Terms of Service before delegating and
          claiming the airdrop.
        </Content>
        <Gap height={5} />
        <Container style={{ overflowX: "hidden" }}>
          <Content style={{ paddingLeft: "30px", paddingRight: "30px" }}>
          <div>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "center", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>
                  <u>LEGAL DISCLAIMER</u>
                </strong>
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                PLEASE READ THE ENTIRETY OF THIS "LEGAL DISCLAIMER"
                SECTION CAREFULLY. NOTHING HEREIN CONSTITUTES LEGAL, FINANCIAL,
                BUSINESS OR TAX ADVICE AND YOU ARE STRONGLY ADVISED TO CONSULT
                YOUR OWN LEGAL, FINANCIAL, TAX OR OTHER PROFESSIONAL ADVISOR(S)
                BEFORE ENGAGING IN ANY ACTIVITY IN CONNECTION HEREWITH. NEITHER
                SEAMLESS FOUNDATION (THE <strong>FOUNDATION</strong>), ANY OF
                THE PROJECT CONTRIBUTORS (THE{" "}
                <strong>SEAMLESS PROJECT CONTRIBUTORS</strong>) WHO HAVE WORKED
                ON SEAMLESS (AS DEFINED HEREIN) OR PROJECT TO DEVELOP SEAMLESS
                IN ANY WAY WHATSOEVER, ANY DISTRIBUTOR AND/OR VENDOR OF SEAM
                TOKENS (OR SUCH OTHER RE-NAMED OR SUCCESSOR TICKER CODE OR NAME
                OF SUCH TOKENS) (THE <strong>DISTRIBUTOR</strong>), NOR ANY
                SERVICE PROVIDER SHALL BE LIABLE FOR ANY KIND OF DIRECT OR
                INDIRECT DAMAGE OR LOSS WHATSOEVER WHICH YOU MAY SUFFER IN
                CONNECTION WITH ACCESSING THE PAPER, DECK OR MATERIAL RELATING
                TO SEAM (THE <strong>TOKEN DOCUMENTATION</strong>) AVAILABLE ON
                THE WEBSITE AT HTTPS://WWW.SEAMLESSPROTOCOL.COM/ (THE{" "}
                <strong>WEBSITE</strong>, INCLUDING ANY SUB-DOMAINS THEREON) OR
                ANY OTHER WEBSITES OR MATERIALS PUBLISHED OR COMMUNICATED BY THE
                FOUNDATION OR ITS REPRESENTATIVES FROM TIME TO TIME.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>Project purpose:</strong> You agree that you are
                acquiring SEAM to participate in and use the Seamless protocol
                and to obtain services on the ecosystem thereon. The Foundation,
                the Seamless Project Contributors and their respective
                affiliates have developed and contributed to the underlying
                source code for Seamless. The Foundation is acting solely as an
                arms&rsquo", length third party in relation to the SEAM
                distribution, and not in the capacity as a financial advisor or
                fiduciary of any person with regard to the distribution of SEAM.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0", fontSize: "12pt"}}>
                <strong>
                  <span style={{ lineHeigth: "115%", fontSize: "11pt"}}>
                    Nature of the Token Documentation:
                  </span>
                </strong>
                <span style={{ lineHeigth: "115%", fontSize: "11pt"}}>
                  &nbsp;The Token Documentation is a conceptual paper that
                  articulates some of the main design principles and ideas for
                  the creation of a digital token to be known as SEAM. The Token
                  Documentation and the Website are intended for general
                  informational purposes only and do not constitute a
                  prospectus, an offer document, an offer of securities, a
                  solicitation for investment, any offer to sell any product,
                  item, or asset (whether digital or otherwise), or any offer to
                  engage in business with any external individual or entity
                  provided in said documentation. The information herein may not
                  be exhaustive and does not imply any element of,
                </span>{" "}
                <span style={{ lineHeigth: "115%", fontSize: "11pt"}}>
                  or solicit in any way, a legally-binding or contractual
                  relationship. There is no assurance as to the accuracy or
                  completeness of such information and no representation,
                  warranty or undertaking is or purported to be provided as to
                  the accuracy or completeness of such information. Where the
                  Token Documentation or the Website includes information that
                  has been obtained from third party sources, the Foundation,
                  the Distributor, their respective affiliates and/or the
                  Seamless Project Contributors have not independently verified
                  the accuracy or completeness of such information. Further, you
                  acknowledge that the project development roadmap, protocol
                  functionality are subject to change and that the Token
                  Documentation or the Website may become outdated as a result;
                  and neither the Foundation nor the Distributor is under any
                  obligation to update or correct this document in connection
                  therewith.
                </span>
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>Validity of Token Documentation and Website:</strong>{" "}
                Nothing in the Token Documentation or the Website constitutes
                any offer by the Foundation, the Distributor, or the Seamless
                Project Contributors to sell any SEAM (as defined herein) nor
                shall it or any part of it nor the fact of its presentation form
                the basis of, or be relied upon in connection with, any contract
                or investment decision. Nothing contained in the Token
                Documentation or the Website is or may be relied upon as a
                promise, representation or undertaking as to the future
                performance of Seamless. The agreement between the Distributor
                (or any third party) and you, in relation to any distribution or
                transfer of SEAM, is to be governed only by the separate terms
                and conditions of such agreement.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                The information set out in the Token Documentation and the
                Website is for community discussion only and is not legally
                binding. No person is bound to enter into any contract or
                binding legal commitment in relation to the acquisition of SEAM,
                and no digital asset or other form of payment is to be accepted
                on the basis of the Token Documentation or the Website. The
                agreement for distribution of SEAM and/or continued holding of
                SEAM shall be governed by a separate set of Terms and Conditions
                or Token Distribution Agreement (as the case may be) setting out
                the terms of such distribution and/or continued holding of SEAM
                (the Terms and Conditions), which shall be separately provided
                to you or made available on the Website. The Terms and
                Conditions must be read together with the Token Documentation.
                In the event of any inconsistencies between the Terms and
                Conditions and the Token Documentation or the Website, the Terms
                and Conditions shall prevail.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>Deemed Representations and Warranties:</strong> By
                accessing the Token Documentation or the Website (or any part
                thereof), you shall be deemed to represent and warrant to the
                Foundation, the Distributor, their respective affiliates, and
                the Seamless Project Contributors as follows:
              </p>
              <ol
                style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "lower-latin outside" }}
              >
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0" }}>
                  in any decision to acquire any SEAM, you have not relied and
                  shall not rely on any statement set out in the Token
                  Documentation or the Website;
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0" }}>
                  you shall at your own expense ensure compliance with all laws,
                  regulatory requirements and restrictions applicable to you (as
                  the case may be);
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0" }}>
                  you acknowledge, understand and agree that SEAM may have no
                  value, there is no guarantee or representation of value or
                  liquidity for SEAM, and SEAM is not an investment product nor
                  is it intended for any speculative investment whatsoever;
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0" }}>
                  none of the Foundation, the Distributor, their respective
                  affiliates, and/or the Seamless Project Contributors shall be
                  responsible for or liable for the value of SEAM, the
                  transferability and/or liquidity of SEAM and/or the
                  availability of any market for SEAM through third parties or
                  otherwise", and
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0" }}>
                  you acknowledge, understand and agree that you are not
                  eligible to participate in the distribution of SEAM if you are
                  a citizen, national, resident (tax or otherwise), and/or
                  domiciliary of a geographic area or country (i) where the
                  distribution of SEAM would be illegal or prohibited under
                  applicable laws and/or (ii) where participation in token
                  distributions is prohibited by applicable law, decree,
                  regulation, treaty, or administrative act", and to this effect
                  you agree to provide all such identity verification document
                  when requested in order for the relevant checks to be carried
                  out.
                </li>
              </ol>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0" }}>
                The Foundation, the Distributor and the Seamless Project
                Contributors do not and do not purport to make, and hereby
                disclaims, all representations, warranties or undertaking to any
                entity or person (including without limitation warranties as to
                the accuracy, completeness, timeliness, or reliability of the
                contents of the Token Documentation or the Website, or any other
                materials published by the Foundation or the Distributor). To
                the maximum extent permitted by law, the Foundation, the
                Distributor, their respective affiliates and service providers
                shall not be liable for any indirect, special, incidental,
                consequential or other losses of any kind, in tort, contract or
                otherwise (including, without limitation, any liability arising
                from default or negligence on the part of any of them, or any
                loss of revenue, income or profits, and loss of use or data)
                arising from the use of the Token Documentation or the Website,
                or any other materials published, or its contents (including
                without limitation any errors or omissions) or otherwise arising
                in connection with the same. Prospective acquirors of SEAM
                should carefully consider and evaluate all risks and
                uncertainties (including financial and legal risks and
                uncertainties) associated with the distribution of SEAM, the
                Foundation, the Distributor and the Seamless Project
                Contributors.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0" }}>
                <strong>SEAM Token:&nbsp;</strong>SEAM are designed to be
                utilised, and that is the goal of the SEAM distribution. In
                particular, it is highlighted that SEAM:
              </p>
              <ol
                type="a"
                style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "lower-latin outside" }}
              >
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                  does not have any tangible or physical manifestation, and does
                  not have any intrinsic value/pricing (nor does any person make
                  any representation or give any commitment as to its value);
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                  is non-refundable, not redeemable for any assets of any entity
                  or organisation, and cannot be exchanged for cash (or its
                  equivalent value in any other digital asset) or any payment
                  obligation by the Foundation, the Distributor or any of their
                  respective affiliates;
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                  does not represent or confer on the token holder any right of
                  any form with respect to the Foundation, the Distributor (or
                  any of their respective affiliates), or their revenues or
                  assets, including without limitation any right to receive
                  future dividends, revenue, shares, ownership right or stake,
                  share or security, any voting, distribution, redemption,
                  liquidation, proprietary (including all forms of intellectual
                  property or licence rights), right to receive accounts,
                  financial statements or other financial data, the right to
                  requisition or participate in shareholder meetings, the right
                  to nominate a director, or other financial or legal rights or
                  equivalent rights, or intellectual property rights or any
                  other form of participation in or relating to Seamless, the
                  Foundation, the Distributor and/or their service providers;
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                  is not intended to represent any rights under a contract for
                  differences or under any other contract the purpose or
                  intended purpose of which is to secure a profit or avoid a
                  loss;
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                  is not intended to be a representation of money (including
                  electronic money), payment instrument, security, commodity,
                  bond, debt instrument, unit in a collective investment or
                  managed investment scheme or any other kind of financial
                  instrument or investment;
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                  is not a loan to the Foundation, the Distributor or any of
                  their respective affiliates, is not intended to represent a
                  debt owed by the Foundation, the Distributor or any of their
                  respective affiliates, and there is no expectation of profit
                  nor interest payment", and
                </li>
                <li style={{ marginLeft: "18pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                  does not provide the token holder with any ownership or other
                  interest in the Foundation, the Distributor or any of their
                  respective affiliates.
                </li>
              </ol>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                Notwithstanding the SEAM distribution, users have no economic or
                legal right over or beneficial interest in the assets of the
                Foundation, the Distributor, or any of their affiliates after
                the token distribution.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                For the avoidance of doubt, neither the Foundation nor the
                Distributor deals in, or is in the business of buying or selling
                any virtual asset or digital payment token (including SEAM). Any
                sale or distribution of tokens would be performed during a
                restricted initial period solely for the purpose of obtaining
                project development funds, raising market/brand awareness, as
                well as community building and social engagement", this is not
                conducted with any element of repetitiveness or regularity which
                would constitute a business.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                To the extent a secondary market or exchange for trading SEAM
                does develop, it would be run and operated wholly independently
                of the Foundation, the Distributor, the distribution of SEAM and
                Seamless. Neither the Foundation nor the Distributor will create
                such secondary markets nor will either entity act as an exchange
                for SEAM.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>Informational purposes only:</strong> The information
                set out herein is only conceptual, and describes the future
                development goals for Seamless to be developed. In particular,
                the project roadmap in the Token Documentation is being shared
                in order to outline some of the plans of the Seamless Project
                Contributors, and is provided solely for{" "}
                <strong>INFORMATIONAL PURPOSES</strong> and does not constitute
                any binding commitment. Please do not rely on this information
                in deciding whether to participate in the token distribution
                because ultimately, the development, release, and timing of any
                products, features or functionality remains at the sole
                discretion of the Foundation, the Distributor or their
                respective affiliates, and is subject to change. Further, the
                Token Documentation or the Website may be amended or replaced
                from time to time. There are no obligations to update the Token
                Documentation or the Website, or to provide recipients with
                access to any information beyond what is provided herein.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>Regulatory approval:</strong> No regulatory authority
                has examined or approved, whether formally or informally, any of
                the information set out in the Token Documentation or the
                Website. No such action or assurance has been or will be taken
                under the laws, regulatory requirements or rules of any
                jurisdiction. The publication, distribution or dissemination of
                the Token Documentation or the Website does not imply that the
                applicable laws, regulatory requirements or rules have been
                complied with.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>Cautionary Note on forward-looking statements:</strong>{" "}
                All statements contained herein, statements made in press
                releases or in any place accessible by the public and oral
                statements that may be made by the Foundation, the Distributor
                and/or the Seamless Project Contributors, may constitute
                forward-looking statements (including statements regarding the
                intent, belief or current expectations with respect to market
                conditions, business strategy and plans, financial condition,
                specific provisions and risk management practices). You are
                cautioned not to place undue reliance on these forward-looking
                statements given that these statements involve known and unknown
                risks, uncertainties and other factors that may cause the actual
                future results to be materially different from that described by
                such forward-looking statements, and no independent third party
                has reviewed the reasonableness of any such statements or
                assumptions. These forward-looking statements are applicable
                only as of the date indicated in the Token Documentation, and
                the Foundation, the Distributor as well as the Seamless Project
                Contributors expressly disclaim any responsibility (whether
                express or implied) to release any revisions to these
                forward-looking statements to reflect events after such date.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>References to companies and platforms:</strong> The use
                of any company and/or platform names or trademarks herein (save
                for those which relate to the Foundation, the Distributor or
                their respective affiliates) does not imply any affiliation
                with, or endorsement by, any third party. References in the
                Token Documentation or the Website to specific companies and
                platforms are for illustrative purposes only.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>English language:</strong> The Token Documentation and
                the Website may be translated into a language other than English
                for reference purpose only and in the event of conflict or
                ambiguity between the English language version and translated
                versions of the Token Documentation or the Website, the English
                language versions shall prevail. You acknowledge that you have
                read and understood the English language version of the Token
                Documentation and the Website.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "6pt", textAlign: "justify", lineHeigth: "115%", widows: "0", orphans: "0"}}>
                <strong>No Distribution:</strong> No part of the Token
                Documentation or the Website is to be copied, reproduced,
                distributed or disseminated in any way without the prior written
                consent of the Foundation or the Distributor. By attending any
                presentation on this Token Documentation or by accepting any
                hard or soft copy of the Token Documentation, you agree to be
                bound by the foregoing limitations.
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "8pt"}}>&nbsp;</p>
              <p style={{ marginTop: "0pt", marginBottom: "8pt"}}>&nbsp;</p>
            </div>
          </Content>
        </Container>
        <StyledCheckboxComponent checked={accepted} setChecked={setAccepted} />
      </ContentBox>
      <Footer
        leftButtonText="Back"
        leftButtonCallback={() => {
          history.push("/governance");
        }}
        rightButtonText="Confirm"
        rightButtonCallback={() => {
          history.push("/delegate-ranking");
        }}
        disabled={!accepted}
      />
    </NarrowColumn>
  );
};

export default Tos;
