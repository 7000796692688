export const theme = {
  colors: {
    blue: "#5298FF",
    red: "#D55555",
    green: "#49B393",
    grey: "#E4E7EB",
    text: {
      medium: "#717171",
    },
    bg: {
      cream: "#F8F0E5",
      purple: "#77037B",
      blue: "#3AB0FF",
      base: "#0455ff",
      headerFooter: "#0C356A",
    },
    gradients: {
      seamless:
        "linear-gradient(248.86deg, #CDF3A2 5%, #21E1E1 15%, #D69BDF 40%, #506FF3 91%)",
      seamlessFooter:
        "linear-gradient(248.86deg, #506FF3 1%, #CDF3A2 15%, #21E1E1 30%, #D69BDF 50%, #506FF3 81%)",
    },
  },
};

export default theme;
