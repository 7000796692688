import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const CheckboxLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const StyledCheckbox = styled.input`
  margin-left: 8px;
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

const StyledCheckboxComponent = ({ setChecked }) => {
  return (
    <CheckboxContainer>
      <CheckboxLabel>
        I have read and agree to the Terms of Service
        <StyledCheckbox
          type="checkbox"
          onChange={(e) => setChecked(e.target.checked)}
        />
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export default StyledCheckboxComponent;
